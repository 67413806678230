<template>
    <div class="m-bargain m-scroll" ref="scroll" @scroll="handleScroll($event)">
        <!-- 头部导航 -->
        <div class="header" :style="`opacity:${(200 - this.offsetTop)  / 100};`"></div>
        <div class="bargain-top">
            <van-swipe :autoplay="5000" lazy-render>
                <van-swipe-item v-for="(item, index) in goods.round_pic_url" :key="index">
                    <img :src="item.url" alt="">
                </van-swipe-item>
            </van-swipe>
            <div class="top-tip">
                <div class="discount">
                    <img src="@/assets/bargain/off_en.png" alt="">
                </div>
                <div class="surplus">
                    <p>Night Market discount <br> express pass</p>
                    <p>Remaining：<span>{{goods.goods_sku.sku_stock.stock}}</span></p>
                </div>
            </div>
            <div class="rule" @click="ruleShow = true">Rules</div>
        </div>
        <div class="bargain-content">
            <div class="bargain-ticket">
                <div class="number">{{goods.goods_info.name}}</div>
                <div class="mooby">*MoobyYoho Richmond Night Market unique designated platform</div>
            </div>
            <div class="bargain-price1">
                <p>Hey there! Do me a favour! </p>
                <p>Help me to get discount on the Express Pass!</p>
            </div>
            <div class="share-btn" @click="handleHelp" v-if="action.is_get_off == 1">
                Click here to give a hand
            </div>
            <div class="share-btn" style="background: #ccc" v-else>
                Click here to give a hand
            </div>
            <div class="buy" @click="$router.push({path:'/en/login',query: {bargain: 1}})">
                <img src="@/assets/bargain/buy_en.png" alt="">
            </div>
            <!-- <div class="bargain-count">
                <div class="dashed"></div>
                <img class="zhong" src="@/assets/bargain/Union-min.png" alt="">
                <img class="xing" src="@/assets/bargain/xingxing.png" alt="">
                <div class="start">
                    <p>Night Market</p>
                    <p>Starts in</p>
                </div>
                <van-count-down :time="action.start_time*1000 - time" >
                    <template #default="timeData">
                        <span class="block">{{ timeData.days }}</span>
                        <span class="colon">Day</span>
                        <span class="block">{{ timeData.hours }}</span>
                        <span class="colon">H</span>
                        <span class="block">{{ timeData.minutes }}</span>
                        <span class="colon">Min</span> 
                    </template>
                </van-count-down>
            </div> -->
        </div>
        <!-- mooby热销 -->
        <div class="hot-sale">
            <div class="hot-title">
                <img src="@/assets/bargain/rt.png" alt="">
                Hot Deals
                <img src="@/assets/bargain/lb.png" alt="">
            </div>
            <goods-columns :data="hotProductList" :loading="hloading"></goods-columns>
            <van-loading v-show="hloading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>
        </div>
        <!-- 提示弹窗 -->
        <van-overlay :show="show" @click="show = false;getData()">
            <div class="wrapper">
                <div class="tip" @click.stop>
                    <div class="offPrice">Good Job! You got your friend a ${{offPrice}} off discount</div>
                    <div class="coupon">Even more, You can also get a $15 OFF $25 MoobyYoho.com Shopping Coupon once you join this activity.</div>
                    <img src="@/assets/bargain/coupon-bg_en.png" alt="">
                    <div class="know"  @click.stop="$router.push({path:'/en/login',query: {bargain: 1}});show = false"></div>
                </div>
            </div>
        </van-overlay>
        <!-- 活动规则弹窗 -->
        <van-popup v-model="ruleShow">
            <p>RULES</p>
            <div class="rule">
                <div class="rule-title">1. How to participate in this activity</div>
                <div class="rule-content">
                    <span>·Only Canada based users can be particiated. Log in MoobyYoho.com to initiate the activity.  Participants can save activity poster or link, and forward it to friends via social media. To get more discounts ,you need to invite friends to join this activity.</span>
                    <div><a href="https://www.moobyyoho.com">*Mooby Yoho：www.moobyyoho.com</a></div>
                    <span>·Help friends to get ticket discounts: user can help friend to get 1 time ticket discount through scan the QR code of the shared poster or click the shared link . Each user(multiple devices in the same network environment are considered as the same user) can only help another user to get discount.</span>
                </div>
                <div class="rule-title">2. Discount Amount</div>
                <div class="rule-content">
                    ·Discount will pop up by a randomly order base, and when the pass discount reached $17.5 in totally, the item cannot continue to receive the discount anymore.
                </div>
                <div class="rule-title">3. Activity Time</div>
                <div class="rule-content">
                    ·The activity is subject to the official requirements of Richmond Night Market, and the first round of participating tickets is 200 tickets. First Come First Serve based.
                </div>
            </div>
            <div class="confirm" @click.stop="ruleShow = false">Confirm</div>
        </van-popup>
        <!-- 置顶 -->
        <m-scroll-top :show="scrollTop" :dom="$refs.scroll"></m-scroll-top>
    </div>
</template>
<script>
import MScrollTop   from '@/components/zh/m-scroll-top.vue'
import GoodsColumns from '@/components/en/goods-columns.vue'
import MixinScroll from '@/untils/js/mixin-scroll.js'
import { hotGoods } from '@/api/zh/index.js'
import { market_info, help } from '@/api/zh/bargain.js'

export default {
    name: 'help',
    mixins: [ MixinScroll ],
    components: { MScrollTop, GoodsColumns },
    data(){
        return {
            offsetTop: 0,
            scrollTop: false,
            time: new Date().getTime(),
            show: false,  // 提示弹窗
            ruleShow: false, //  规则提示窗
            hloading: false,
            hotProductList: [], // 热销商品列表
            action: {},  // 活动信息
            goods: {goods_sku:{sku_stock:{}}, goods_info: {}}, // 商品信息
            page: 1,
            islock: false,
            id: '',
            offPrice: 0 // 优惠金额
        }
    },
    methods:{
        handleScroll(e) {
            this.offsetTop = e.target.scrollTop
            if(e.target.scrollTop >= 550){
                this.scrollTop = true
            }else{
                this.scrollTop = false
            }
            if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 0){
                if(!this.islock){
                    e.preventDefault()
                    e.stopPropagation()
                    this.hloading = true
                    this.page++
                    this.getHotGoods()
                }
            }
        },
        // 帮忙砍价
        handleHelp() {
            this.$store.commit('load')
            help({ night_market_join_log_id : this.id }).then(res => {
                if(res) {
                    this.offPrice = res.data.off_price
                    this.show = true
                }
            }).finally(() => {
                this.$store.commit('unload')
            })
        },
        // 商品信息
        getData() {
            market_info({night_market_join_log_id: this.id}).then(res => {
                if(res.code == 20000) {
                    this.action = res.data.action
                    this.goods = res.data.goods
                    if(res.data.user != null) {
                        if(this.action.ip == res.data.user.ip) {
                            this.$router.replace('/en/bargain')
                        }
                    }
                }
            })
        },
        // mooby热销
        getHotGoods() {
            // 获取热门单品列表
            this.hloading = true
            hotGoods({page: this.page,is_page: 1,per_page: 20}).then(res => {
                this.hotProductList = this.hotProductList.concat(res.data.data)
                if (res.data.data.length == 0) {
                    this.noMore = true
                }
            }).finally(() => {
                this.hloading = false
            })
        },
        
    },
    created() {
        if(this.$route.query.id) {
            this.id = this.$route.query.id
        }
        this.getData()
        this.getHotGoods()
    }
}
</script>
<style lang="less" scope>
.m-bargain {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    background: #f2f2f2;
    .header {
        width: 100%;
        height: 44px;
        text-align: center;
        line-height: 44px;
        position: fixed;
        top: 0;
        left: 0;
        color: #fff;
        font-size: 18px;
        z-index: 22;
    }
    .bargain-top {
        width: 100%;
        height: 246px;
        background: #efefef;
        position: relative;
        .van-swipe {
            height: 100%;
            .van-swipe-item {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .rule {
            position: absolute;
            right: 0;
            bottom: 76px;
            width: 78px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border-radius: 14px 0 0 14px;
            font-size: 12px;
            background: #fff;
            color: #F35F42;
        }
        .top-tip {
            position: absolute;
            bottom: -1px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 60px;
            font-size: 12px;
            background: url('../../../assets/bargain/tip.png') no-repeat;
            background-size: 100% 100%;
            .discount {
                margin-left: 12px;
                margin-top: 5px;
                width: 170px;
                height: 44px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .surplus {
                color: #5A0000;
                margin-right: 10px;
                text-align: center;
                p:nth-child(2) {
                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .bargain-content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: column;
        border-radius: 0 0 8px 8px;
        background: #fff;
        margin-bottom: 10px;
        .bargain-ticket {
            margin-top: 20px;
            width: 100%;
            font-size: 16px;
            text-align: left;
            padding: 0 20px;
            .number {
                font-weight: 600;
            }
            .mooby {
                color: #5989ED;
                font-size: 12px;
                margin:5px 0 20px;
            }
            .price {
                color: #ED2A24;
                margin-bottom: 10px;
                span {
                    color: #999;
                    font-size: 12px;
                    text-decoration:line-through;
                }
            }
        }
        .bargain-count {
            position: relative;
            width: 343px;
            height: 58px;
            border-radius: 0 0 15px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;
            text-align: center;
            background: linear-gradient(271.71deg,  #FFC7B4 -2.49%, #FFE9D8 101.72%);
            .dashed {
                position: absolute;
                left: 50%;
                top: -1px;
                transform: translateX(-50%);
                width: 351px;
                border-top: 2px dashed #5D8DEC;
            }
            .zhong {
                position: absolute;
                top: 2px;
                left: 5px;
                width: 37px;
                height: 45px;
            }
            .xing {
                position: absolute;
                bottom: 9px;
                right: 3px;
                width: 16px;
                height: 20px;
            }
            .start {
                margin-right: 10px;
                margin-left: 10px;
                z-index: 2;
                p:nth-child(2) {
                    color: #591400;
                    font-size: 16px;
                    font-weight: 700;
                }
                p:nth-child(1) {
                    color: #CA7153;
                    font-size: 12px;
                }
            }
            .van-count-down {
                .block {
                    display: inline-block;
                    width: 36px;
                    height: 36px;
                    border-radius: 5px;
                    background: #fff;
                    text-align: center;
                    line-height: 36px;
                    font-weight: 700;
                }
                .colon {
                    margin: 0 8px;
                    
                }
            }
        }
        .bargain-price1 {
            width: 290px;
            text-align: center;
            font-size: 14px;
            margin:0 auto 16px;
            padding: 10px;
            color: #666;
            background: url('../../../assets/bargain/share.png') no-repeat;
            background-size: 100% 100%;
            
        }
        .share-btn {
            font-size: 18px;
            width: 311px;
            height: 44px;
            line-height: 44px;
            border-radius: 22px;
            margin-bottom: 8px;
            background: linear-gradient(270deg, #FF4745 -13.18%, #FF9E6E 113.18%);
            text-align: center;
            color: #fff;
        }
        .buy {
            width: 336px;
            height: 69px;
            img {
                width: 336px;
                height: 69px;
            }
        }
    }
    .hot-sale {
        .hot-title {
            text-align: center;
            margin: 10px 0;
            font-size: 18px;
            img {
                width: 16px;
                height: 10px;
            }
        }
        .goods-price {
            img {
                width: 0 !important;
                height: 0 !important;
            }
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tip {
            width: 288px;
            height: 345px;
            position: relative;
            color: #fff;
            text-align: center;
            img {
                width: 100%;
                height: 100%;
                
            }
            .offPrice {
                position: absolute;
                bottom: 135px;
                width: 250px;
                font-size: 18px;
                left: 50%;
                transform: translateX(-50%);
            }
            .coupon {
                position: absolute;
                bottom: 68px;
                width: 244px;
                font-size: 14px;
                left: 50%;
                transform: translateX(-50%);
            }
            .know {
                position: absolute;
                bottom: 12px;
                left: 50%;
                transform: translateX(-50%);
                width: 170px;
                height: 40px;
            }
        }
    }
    .van-overlay {
        z-index: 99 !important;
    }
    .van-popup {
        width: 291px;
        padding: 20px 20px 0;
        background: #fff;
        border-radius: 12px;
        font-size: 14px;
        box-sizing: border-box;
        p {
            text-align: center;
            margin-bottom: 12px;   
            font-size: 16px;
            font-weight: 600;   
        }
        .rule {
            overflow-x: hidden;
            overflow-y: auto;
            height: 377px;
            .rule-title {
                font-weight: 600;
                margin-bottom: 12px;
            }
            .rule-content {
                margin-bottom: 20px;
                div {
                    margin-bottom: 5px;
                    a {
                        color: #48A2FF;
                    }
                }
            }
        }
        .confirm {
            margin-top: 5px;
            box-shadow: inset 0px 1px 0px rgba(153, 153, 153, 0.25);
            text-align: center;
            padding: 13px 0;
            color: #FF5049;
        }
        
    }
}
.like {
    width: 0 !important;
    height: 0 !important;
}
</style>