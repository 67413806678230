<template>
  <transition name="van-fade">
    <div v-show="show" @click="onClick"  class="m-scroll-top">Top</div>
  </transition>
</template>

<script>
export default {
  name: "MDot",
  props: ['show','dom'],
  methods: {
    onClick(){
      let speedIndex = 20
      let documentHeight = this.dom.scrollTop
      let timer = setInterval(() => {

          documentHeight -= documentHeight / speedIndex

            this.dom.scrollTo(0,documentHeight)
          // 停止
          if(documentHeight <= 2) {
            this.dom.scrollTo(0,0)
            clearInterval(timer)
          }
      }, 10)
    }
  }
}
</script>

<style lang="less" scoped>
.m-scroll-top {
  position: fixed;
  right: 16px;
  bottom: 100px;
  background:rgba(0, 0, 0, 0.45);
  width: 36px;
  height:36px;
  font-size: 16px;
  border-radius: 50%;
  color: #fff;
  line-height: 36px;
  z-index: 999;
  text-align: center;
}
</style>