

import { get, post, put } from '@/untils/js/axios.js'

// 活动信息
export const market_info = params => get(`/night/market/info`, params)

// 参加活动
export const join = params => post(`/night/market/join`, params)

// 优惠券通知
export const coupon_unread = params => get(`/user/coupon/unread/list`, params)

// 好友助力列表
export const off_log = params => get(`/night/market/off/log`, params)

// 好友助力列表
export const help = params => post(`/night/market/off/log`, params)

// 确认订单
export const getSelfInfo = params => get(`/night/market/confirm/order`, params)

// 添加自提人信息
export const add_user_pick = params => post(`/user/pick`, params)

// 修改自提人信息
export const put_user_pick = (id, params) => put(`/user/pick/${id}`, params)

// 创建订单
export const getTitle = params => post(`/night/market/create/order`, params)