/** 
 *   首页
 */
import { get,post } from '@/untils/js/axios.js'

// 获取独立板块列表
export const sectionList = params => get('/special/column-list', params)

// 获取新品首发数据
export const homeNew = params => get(`/goods/columns-goods/2`, params)

// 获取热门单品数据
export const hotGoods = params => get(`/goods/columns-goods/3`, params)

// 为你推荐商品列表显示(用户自定义)
export const userRemGoodsList = params => get('/goods/columns-goods/1', params)

// 为你推荐商品列表显示(全部)
export const userAllRemGoodsList = params => get('/goods/recommend/index', params)

// 获取地址列表
export const getCityList = params => get('/city/getcity', params)

// 获取分类显示下的商品
export const getSectionTypeGoods = params => get('/special/goods-list', params)

// 获取首页轮播图
export const getBanner = id => get(`/banner/${id}`)

// 获取首页 店铺列表
export const business = params => get('/shops/list', params)

// 店铺列表页面 
export const shops = params => get('/shops/list', params)

// 获取新品预售
export const preSaleList = (id,params) => get(`/goods/pre-sale/${id}`, params)

// 判断是否参加过活动
export const user_activity_is = params => get('user_activity_is', params)

// 第二套活动判断是否参加过活动
export const user_activity_is_two = params => get('user_activity_is_two', params)

// 翻牌子抽奖
export const is_gift = params => get('user/is-gift', params)

// 首页主题活动
export const activityTheme = params => get('/activity/theme', params)

// 首页主题活动列表
export const activeList = (id,params) => get(`/activity/theme-main/${id}`, params)

// 落地页活动
export const landingPage = params => get('/activity/landing', params)

// 落地页活动列表
export const landingList = (id, params) => get(`/activity/landing-main/${id}`, params)

// 上传黑五海报图
export const poster_add  = params => post('/order/poster/add', params)

// 查看黑五海报图
export const poster_get = params => get(`/order/poster/get`, params)

// 黑五扫码登陆
export const balance_return = params => post(`/balance/return`, params)